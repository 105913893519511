import React from "react";
import { NFO, NFOLogo, backArrowImage } from "../../../../Assets/images";
import { useNavigate } from "react-router";

const OrderHistoryHeader = () => {
  const navigate = useNavigate();
  return (
    <header className="order-history-header">
      <div className="order-history-header-summary">
        <button
          className="order-history-back-button"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img
            className="order-history-back-button-image"
            src={backArrowImage}
            alt="<="
          />
        </button>
        <div className="order-history-header-text-wrapper">
          Purchase History
        </div>
      </div>
      <img className="order-history-header-NFO-logo" alt="Nfo logo" src={NFO} />
    </header>
  );
};

export default OrderHistoryHeader;
