import React from "react";
import "./MobileDashboardHeader.css";
import { NFO, clock, userProfileVector } from "../../../../Assets/images";
import { useNavigate } from "react-router";

interface dashboardHeaderProps {
  cbHandleOpenUserInfo: () => void;
}

const MobileDashboardHeader = (props: dashboardHeaderProps) => {
  const navigate = useNavigate();
  return (
    <header className="mobile-dashboard-header">
      <img
        className="mobile-dashboard-header-NFO-logo"
        alt="Nfo logo"
        src={NFO}
      />
      <button
        className="mobile-dashboard-header-user-profile"
        onClick={props.cbHandleOpenUserInfo}
      >
        <img src={userProfileVector} alt="O" />
      </button>
      {/* <button
        className="mobile-dashboard-header-summary"
        onClick={() => {
          navigate("/orderHistory");
        }}
      >
        <img src={clock} alt="O" />
        <div className="mobile-dashboard-header-orderHistory-text">
          Order History
        </div>
      </button> */}
    </header>
  );
};

export default MobileDashboardHeader;
