import React, { useEffect } from "react";
import "./OrderHistory.css";
import "./Components/OrderComponentsStyles.css";
import OrderSubtitle from "./Components/OrderSubtitle/OrderSubtitle";
import OrderHistoryHeader from "./Components/OrderHistoryHeader/OrderHistoryHeader";
import { bottomBackground, cancelPolicyImage } from "../../Assets/images";

const OrderHistory = () => {
  return (
    <div className="order-history-page">
      <OrderHistoryHeader />
      <OrderSubtitle />
    </div>
  );
};

export default OrderHistory;
