import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";

import { clock, arrowLeftWhite } from "../../Assets/images";
import { mailIcon } from "../../Assets/svgs";

//@ts-ignore
import { load } from "@cashfreepayments/cashfree-js";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Alert, Button, CircularProgress, Modal } from "@mui/material";

import { AuthContext } from "../../App";

import DashboardHeader from "./DashboardComponents/DashboardHeader/DashboardHeader";
import ExamCard from "./DashboardComponents/ExamCard/ExamCard";
import Bookshelf from "./DashboardComponents/Bookshelf/Bookshelf";
import NFOSessionCard from "./DashboardComponents/NFOSessionCard/NFOSessionCard";
import MockQuizCard from "./DashboardComponents/MockQuizCard/MockQuizCard";
import MobileDashboardHeader from "./DashboardComponents/MobileHeaderDashboard/MobileDashboardHeader";
import MobileUserInfoModal from "./DashboardComponents/MobileUserInfoModal/MobileUserInfoModal";
import MobileMockQuizCard from "./DashboardComponents/MockQuizCard/MobileMockQuizCard";

import "./Dashboard.css";
import "./DashboardComponents/DashboardComponentsStyles.css";
import { initializeApp } from "firebase/app";
import { getAnalytics, setUserId, setUserProperties } from "firebase/analytics";
import {
  fAnalytics,
  firebaseConfig,
} from "../../Utils/firebase/firebaseConfig";

const Dashboard = () => {
  const navigate = useNavigate();
  const {
    auth: { student_username, name, grade, school, showMockOlympiad },
  } = useContext(AuthContext);
  const urlParams = new URLSearchParams(window.location.search);
  console.log({ student_username });
  const orderId = urlParams.get("order_id");
  console.log("order_id param = ", orderId);
  const page = urlParams.get("page");
  console.log("page param = ", page);
  const [purchasedItems, setPurchasedItems] = useState(false);
  const [syncingPurchase, setSyncingPurchase] = useState(true);
  const { param } = useParams<{ param: string }>();
  console.log("param = ", param);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [mobileUserInfoVisible, setMobileUserInfoVisible] = useState(false);
  if (student_username) {
    setUserId(fAnalytics, student_username);
    setUserProperties(fAnalytics, {
      userId: student_username,
      grade: grade,
      school: school,
    });
  }
  const handleCloseMobileUserInfo = () => {
    setMobileUserInfoVisible(false);
  };

  const handleShowMobileUserInfo = () => {
    setMobileUserInfoVisible(true);
  };

  const openInSameTab = (url: string): void => {
    window.location.href = url;
  };

  const getFormattedExamDate = (dateString: string) => {
    console.log("Datestring = ", dateString);
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const formattedDate = `${day}${ordinalSuffix(day)} ${month}, ${year}`;
    // Function to get the ordinal suffix for a given day
    function ordinalSuffix(day: number) {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }
    return formattedDate;
  };

  const getFormattedExamTime = (dateString: string) => {
    const startDate = new Date(dateString);
    const hours = startDate.getHours();
    const minutes = startDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes =
      // minutes === 0 ? "" :
      minutes.toString().padStart(2, "0");

    const startTime =
      // minutes === 0
      // ? `${formattedHours}${ampm}`
      // :
      `${formattedHours}:${formattedMinutes} ${ampm}`;

    console.log(`${hours}:${minutes}`);

    // Add 1.00 hour to the start time
    const laterTime = new Date(startDate.getTime() + 1 * 60 * 60 * 1000);

    // Extracting later time
    const laterHours = laterTime.getHours();
    const laterMinutes = laterTime.getMinutes();
    const laterAmPm = laterHours >= 12 ? "PM" : "AM";
    const formattedLaterHours = laterHours % 12 === 0 ? 12 : laterHours % 12;
    const formattedLaterMinutes =
      // laterMinutes === 0 ? "" :
      laterMinutes.toString().padStart(2, "0");
    const laterTimeString =
      // laterMinutes === 0
      // ? `${formattedLaterHours}${laterAmPm}`
      // :
      `${formattedLaterHours}:${formattedLaterMinutes} ${laterAmPm}`;

    console.log("Formatted later time = ", laterTimeString);

    return startTime + " to " + laterTimeString;
  };

  const handleFirstButtonClick = () => {
    navigate("/certificate-viewer", {
      state: {
        type: "examrules",
        link: "https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/NFO+Assets+8th+May+2024/Rules.pdf",
      },
    });
  };

  const handleSecondButtonClick = () => {
    navigate("/report-viewer", {
      state: {
        type: "examrules",
        link: "https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/NFO+Assets+8th+May+2024/Rules.pdf",
      },
    });
  };
  const handleCertificateViewer = () => {
    navigate("/certificate-viewer", {
      state: {
        type: "examrules",
        link: "https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/NFO+Assets+8th+May+2024/Rules.pdf",
      },
    });
  };

  const handleExamRulesClick = () => {
    navigate("/pdf-preview", {
      state: {
        type: "examrules",
        link: "https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/NFO+Assets+8th+May+2024/Rules.pdf",
      },
    });
  };

  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div className="portal">
      <MobileDashboardHeader cbHandleOpenUserInfo={handleShowMobileUserInfo} />
      <div className="contentwrap">
        <DashboardHeader />
        {/* <div className="mobile-exam-cards">
          {getExamCardComponent(examStatus)}
        </div> */}
        <div className="contentwrap1">
          <div className="studentid-wrap">
            <div className="studentid">
              <b className="name">{name}</b>
              <div className="line" />
              <div className="grade">{grade}th Grade</div>
              <div className="line" />
              <div className="grade">
                <div className="school">{school} </div>
              </div>
              <div className="line" />
              <div className="grade">
                <div className="school">Roll No.- {student_username} </div>
              </div>
              <div className="line" />
              <div
                onClick={() => {
                  Cookies.remove("accessToken");
                  sessionStorage.clear();
                  localStorage.clear();
                  window.location.reload();
                }}
                className="sign-out"
              >
                Sign-Out
              </div>
            </div>
            <div className="laptop-exam-cards">
              <ExamCard />
            </div>

            <button className="supportbutton">
              <img className="vector-icon" alt="" src={mailIcon} />
              <a
                className="support-text"
                href="mailto:support@nationalfinanceolympiad.com"
              >
                Contact NFO Support
              </a>
            </button>
          </div>

          <div className="rightpanel">
            <div className="pagetitle">
              <b className="nfo-content">NFO Study Material</b>
              {/* <img className="line" alt="" src={TitleLine} /> */}
              <div className="line"></div>
            </div>

            <Bookshelf />

            <div className="nfo-portal-v2-bottom-cards">
              {!(grade > 8 && grade < 11) && <NFOSessionCard />}

              {showMockOlympiad && (
                <>
                  <MockQuizCard
                    cbHandleMockClick={() => {
                      const token = localStorage.getItem("accessToken");
                      openInSameTab(
                        `https://olympiad.nationalfinanceolympiad.com?token=${token}&userName=${student_username}`
                      );
                    }}
                  />
                  <MobileMockQuizCard
                    cbHandleMockClick={() => {
                      const token = localStorage.getItem("accessToken");
                      openInSameTab(
                        `https://olympiad.nationalfinanceolympiad.com?token=${token}&userName=${student_username}`
                      );
                    }}
                  />
                </>
              )}
            </div>

            <div className="mobile-bottom-buttons">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <button className="mobile-supportbutton">
                  <img className="mobile-vector-icon" alt="" src={mailIcon} />

                  <a
                    className="mobile-support-text"
                    href="mailto:support@nationalfinanceolympiad.com"
                  >
                    Contact NFO Support
                  </a>
                </button>
                <button
                  className="mobile-supportbutton"
                  onClick={() => {
                    navigate("/orderHistory");
                  }}
                >
                  <img className="mobile-vector-icon" src={clock} alt="O" />
                  <div className="mobile-support-text">Purchase History</div>
                </button>
              </div>
              <div>
                <button
                  className="mobile-signout-button"
                  onClick={() => {
                    Cookies.remove("accessToken");
                    sessionStorage.clear();
                    localStorage.clear();
                    window.location.reload();
                  }}
                >
                  Signout of NFO
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <b className="footer">
          Pockvue Solutions Pvt Ltd | An initiative by Streak
        </b> */}
      </div>
      {error.length > 0 && (
        <Alert
          style={{ position: "absolute", top: 10, left: 10 }}
          severity="error"
        >
          {error}
        </Alert>
      )}
      <MobileUserInfoModal
        isVisible={mobileUserInfoVisible}
        onClose={() => {
          handleCloseMobileUserInfo();
        }}
      >
        <div className="mobile-userInfoWrap">
          <button
            className="mobile-userProfileBackButton"
            onClick={handleCloseMobileUserInfo}
          >
            <img src={arrowLeftWhite} alt="&lt;-" />
            &nbsp;Go back
          </button>
          <br />
          <div className="mobile-studentid">
            <b className="mobile-name">{name}</b>
            <div className="line" />
            <div className="mobile-grade">{grade}th Grade</div>
            <div className="line" />
            <div className="mobile-grade">
              <div className="mobile-school">{school} </div>
            </div>
            <div className="line" />
            <div className="mobile-grade">
              <div className="mobile-school">Roll No.- {student_username} </div>
            </div>
          </div>
        </div>
      </MobileUserInfoModal>
    </div>
  );
};

export default Dashboard;
