import React, { useEffect } from "react";
import "./PdfPreview.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";

import {
  DocumentAskPasswordEvent,
  Viewer,
  Worker,
} from "@react-pdf-viewer/core";
import "../../../node_modules/@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { supabase } from "../../Utils/api/supabaseClient";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import {
  ToolbarSlot,
  defaultLayoutPlugin,
} from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { ArrowLeft } from "@mui/icons-material";
import { backArrowImage, backArrowWithoutFill } from "../../Assets/images";
import { downloadIcon } from "../../Assets/svgs";
import api from "../../Utils/axios/axiosMW";
import axios from "axios";
import { error } from "console";

function PdfPreview() {
  const [pdfFile, setPDFFile] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { type, classValue, link, goBack } = location.state;
  const zoomPluginInstance = zoomPlugin();
  const defaultPluginInstance = defaultLayoutPlugin();
  const { Zoom } = zoomPluginInstance;

  const folderPath = (type: string) => {
    switch (type) {
      case "examrules":
        return `https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/NFO+Assets+8th+May+2024/Rules.pdf`;
      case "guidebook":
        return `https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/guidebooks/grade${classValue}/guidebook_${classValue}_protected.pdf`;
      case "workbook":
        return `https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/workbooks/grade${classValue}/workbook_${classValue}_protected.pdf`;
      case "nationalGuidebookpreview":
        return `https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/nationalGuideBooks/grade${classValue}/nationalguidebooksprev_${classValue}.pdf`;
      case "nationalGuidebookFull":
        return `https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/nationalGuideBooks/grade${classValue}/nationalguidebooksprev_${classValue}.pdf`;
      default:
        return `examrules/exam_rules.pdf`;
    }
  };

  const onDocumentAskPassword = (e: DocumentAskPasswordEvent) => {
    console.log("Type of Book =", type);
    return e.verifyPassword("J4NY]fy[1#!66#6");
    // if (
    //   link ===
    //   "https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/nationalGuideBooks/grade6/nationalguidebooksfull_6.pdf"
    // ) {
    //   return e.verifyPassword("Z&58#88wJ-k-Qs#");
    // }
    // switch (type) {
    // case "Financial Literacy Guidebook":
    //   console.log("e.verifyPassword = ", e.verifyPassword("J4NY]fy[1#!66#6"));
    //   return e.verifyPassword("J4NY]fy[1#!66#6");
    // case "Financial Literacy Guidebook":
    //   return e.verifyPassword("J4NY]fy[1#!66#6");
    // case "Financial Literacy Guidebook":
    //   return e.verifyPassword("J4NY]fy[1#!66#6");
    // case "Financial Literacy Guidebook":
    //   return e.verifyPassword("Z&58#88wJ-k-Qs#");
    // case "Revision Material":
    //   return e.verifyPassword("J4NY]fy[1#!66#6");
    // default:
    //   return `examrules/exam_rules.pdf`;
    // }
  };

  const fetchPdf = async () => {
    // const link = folderPath(type);

    if (link) {
      setPDFFile(link);
    } else {
      console.error("Error getting pdf link");
    }
  };

  useEffect(() => {
    fetchPdf();
  }, [location.state]);

  // Function to replace a substring from the end of a string
  function replaceSubstringFromEnd(
    originalString: string,
    substringToReplace: string,
    replacement: string
  ): string {
    if (originalString.endsWith(substringToReplace)) {
      return originalString.slice(0, -substringToReplace.length) + replacement;
    }
    return originalString;
  }

  function extractFilenameFromURL(url: string): string {
    // Split the URL by '/'
    const parts = url.split("/");

    // Get the last part of the URL, which is the filename
    const filename = parts[parts.length - 1];
    // const filename = parts[parts.length-2]+"_"+parts[parts.length - 1];

    // Remove any query parameters if present
    const filenameWithoutParams = filename.split("?")[0];

    return filenameWithoutParams;
  }

  function formatFilename(filename: string): string {
    // Replace any spaces with underscores
    let formattedFilename = filename.replace(/\s+/g, "_");

    // Replace '+' with underscores
    formattedFilename = formattedFilename.replace(/\+/g, "_");
    // Replace '-' with underscores
    formattedFilename = formattedFilename.replace(/-/g, "_");

    return formattedFilename;
  }

  const handleDownloadPdf = () => {
    //CURRENTLY DOWNLOADING DUMMY FILE TO PREVENT PASSWORD ASKING ISSUE.
    //CHANGE LATER WHEN ALL ARE PUT IN S3 INSTANCE BUCKET

    const oldLink = link;
    const downloadableLink = replaceSubstringFromEnd(
      oldLink,
      "_protected.pdf",
      ".pdf"
    );

    // Extract filename from URL
    const filename = extractFilenameFromURL(downloadableLink);

    // Format filename
    const formattedFilename = formatFilename(filename);

    axios({
      method: "get",
      // url: "https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/NFO+Assets+8th+May+2024/Rules.pdf",
      url: downloadableLink,
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = formattedFilename; // Specify the filename for the downloaded file
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.log("Error downloading PDF: ", error));
  };

  const fileType = ["application/pdf"];
  return (
    <div className="body" onContextMenu={(e) => e.preventDefault()}>
      {/* <img src={NFOLogo} alt="logo" className="logo" /> */}
      {goBack && (
        <div
          onClick={() => {
            // navigate("/orderHistory");
            navigate(-1);
          }}
          className="header"
          onContextMenu={(e) => e.preventDefault()}
        >
          <b className="back">
            <img src={backArrowWithoutFill} alt="&larr;" />
            <b>Go back</b>
          </b>
        </div>
      )}
      {!goBack && (
        <div
          className="header"
          onContextMenu={(e) => e.preventDefault()}
        >
          <b
            className="back"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            &larr; Back to Portal
          </b>
          {/* <img src={background} alt="background" className="bg"></img> */}
          <div className="download-div-wrapper">
            <img
              className="pdf-download-img"
              src={downloadIcon}
              alt="Download"
              onClick={handleDownloadPdf}
            />
            <b onClick={handleDownloadPdf}>Download</b>
          </div>
        </div>
      )}
      <div className="container" onContextMenu={(e) => e.preventDefault()}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Zoom levels={[0.8, 1.2, 1.6, 2.4, 3.2]} />
        </div>
        <div
          className="pdf-container"
          onContextMenu={(e) => e.preventDefault()}
        >
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
            <div
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "10px",
                boxShadow: "20px",
              }}
            >
              {pdfFile ? (
                <Viewer
                  plugins={[zoomPluginInstance]}
                  onDocumentAskPassword={onDocumentAskPassword}
                  renderProtectedView={() => {
                    return (
                      <div
                        style={{
                          backgroundColor: "#000",
                          flex: 1,
                          display: "flex",
                        }}
                      ></div>
                    );
                  }}
                  fileUrl={pdfFile}
                />
              ) : (
                <div></div>
              )}
            </div>
          </Worker>
        </div>
      </div>
    </div>
  );
}

export default PdfPreview;
