import { FunctionComponent, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../App";
import generatePDF, { Resolution, Margin, Options } from "react-to-pdf";
import {
  certificateItems,
  getCertificateItems,
} from "../../../../Utils/api/auth";
import FinalReport from "./FinalReport";
import { CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { backArrowImage } from "../../../../Assets/images";
import { useNavigate } from "react-router";

const ReportViewer = () => {
  const {
    auth: { student_username },
  } = useContext(AuthContext);
  console.log({ student_username });

  const [data, setData] = useState<certificateItems>();

  const getCertificateData = async () => {
    setLoader(true);
    getCertificateItems(student_username).then((response) => {
      //@ts-ignore
      if (response.data.status === "Success") {
        //@ts-ignore
        setData(response.data);
      }
      setLoader(false);
    });
  };
  useEffect(() => {
    getCertificateData();
  }, []);

  const [loader, setLoader] = useState(false);
  const options: Options = {
    filename: data?.data.studentUsername,
    // default is `save`
    method: "save",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.LOW,

    page: {
      margin: Margin.NONE,
      format: "A4",
      orientation: "portrait",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    // customize any value passed to the jsPDF instance and html2canvas
    // function
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true,
        orientation: "portrait",
      },

      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true,
        scale: 3,
      },
    },
  };

  const navigation = useNavigate();
  const openPDF = () => {
    setLoader(true);
    generatePDF(() => document.getElementById("wrapper"), options).then(() => {
      setLoader(false);
    });
  };

  return (
    <div style={{ backgroundColor: "#F1FAFE" }}>
      {data && (
        <div>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={backArrowImage}
              alt=""
              onClick={() => {
                navigation("/dashboard");
              }}
              style={{
                cursor: "pointer",
                marginBottom: "5px",
                position: "absolute",
                left: "4%",
                top: "5.5%",
              }}
            />
            <LoadingButton
              variant="contained"
              loading={loader}
              onClick={openPDF}
              style={{
                marginTop: 50,
                marginBottom: 50,
                height: 40,
              }}
            >
              Download Report
            </LoadingButton>

            <FinalReport id={"wrapper"} data={data} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportViewer;
