import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { format } from 'date-fns'
import { motion, AnimatePresence } from 'framer-motion'
import { CircularProgress } from '@mui/material'

import NFOSessionHistoryHeader from './NFOSessionHistoryComponents/NFOSessionHistoryHeader'
import { SessionBookedSlots, getPreOlympiadBookedSlots } from '../../Utils/api/auth'

import SessionDetails from '../NFOSession/NFOSessinComponents/SessionDetails'
import './NFOSessionHistory.css'
import { downArrow } from '../../Assets/svgs'

const res = {
  data: {
    "status": "Success",
    "status_code": 200,
    "data": [
      {
        "batch_start_time": "2024-09-04T18:00:00",
        "batch_end_time": "2024-09-04T19:00:00",
        "meet_link": "https://meet.google.com/fdr-zeze-xbe"
      },
      {
        "batch_start_time": "2024-09-05T18:00:00",
        "batch_end_time": "2024-09-05T19:00:00",
        "meet_link": "https://meet.google.com/fdr-zeze-xbe"
      },
      {
        "batch_start_time": "2024-09-05T18:00:00",
        "batch_end_time": "2024-09-06T19:00:00",
        "meet_link": "https://meet.google.com/fdr-zeze-xbe"
      },
      {
        "batch_start_time": "2024-09-09T18:00:00",
        "batch_end_time": "2024-09-09T19:00:00",
        "meet_link": "https://meet.google.com/fdr-zeze-xbe"
      },
      {
        "batch_start_time": "2024-09-10T18:00:00",
        "batch_end_time": "2024-09-10T19:00:00",
        "meet_link": "https://meet.google.com/fdr-zeze-xbe"
      }
    ],
    "message": ""
  }
}

const BOOKED_SLOTS_INIT = {
  "status": "Error",
  "message": "no slots booked",
  "data": ""
}

const imageUrl = "https://nationalfinanceolympiadasset.s3.ap-south-1.amazonaws.com/Icons/Chat+bibble.png";

const NFOSessionHistory = () => {
  const navigate = useNavigate()

  const [bookedSlots, setBookedSlots] = useState<any>('')
  const [toggleTiming, setToggleTiming] = useState(true)
  const [toggleDesc, setToggleDesc] = useState(false)
  const [loading, setLoading] = useState(false)

  const getSessionStatus = (starttime: string, endtime: string) => {
    const today = new Date();
    const start = new Date(starttime);
    const end = new Date(endtime);

    return today < start
      ? "future"
      : start <= today && today <= end
        ? "present"
        : "past";
  };

  const openInSameTab = (link: string) => {
    window.location.href = link
  }

  const getAccessLink = () => {
    const currentSession = bookedSlots.find((slot: any) => getSessionStatus(slot.batch_start_time, slot.batch_end_time) === 'present')

    if (currentSession) {
      return (
        <button
          type='button'
          className='nsh-btn'
          onClick={() => openInSameTab(currentSession.meet_link)}
        >Join session</button>
      )
    }
    else return <></>
  }

  const getSlots = async () => {
    try {
      setLoading(true)

      const res = await getPreOlympiadBookedSlots()
      const data = res.data.data
      // const data = ""    // for testing purpose when no session is booked
      setBookedSlots(data)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getSlots()
  }, [])

  // useEffect(() => {

  // }, [toggleTiming, toggleDesc])



  return (
    <div className='nsh-page'>
      <NFOSessionHistoryHeader />
      <div className="nsh-page-body">
        <div className="nsh-container">
          <div className="nsh-container-header">
            <span className="text-depressed">Session Description</span>
          </div>
          <div className="nsh-container-body">
            {loading ?
              <div style={{ width: "fit-content", margin: "auto" }}>
                <CircularProgress size={25} />
              </div>
              : !bookedSlots ? (
                <div className="nsh-empty">
                  <span className='nsh-empty-txt text-depressed'>You hve not registered for an NFO Expert session yet</span>
                  <button
                    onClick={() => navigate('/nfo-classes')}
                    className="nsh-btn"
                  >
                    New NFO Session
                  </button>
                </div>
              ) :
                <div className="nsh-session">
                  <div className="nsh-session-photo">
                    <img src={imageUrl} alt="session" width={"100%"} />
                  </div>
                  <div className="w-full">
                    <div className="nsh-session-details-head w-full">
                      <div className="nsh-session-desc">
                        <p className="nsh-session-desc-head">
                          Session on {format(bookedSlots[0].batch_start_time, "eeee")}
                        </p>
                        <p className="nsh-session-desc-body">
                          All the details about the session can be found here and
                          have been also sent to your registered E-mail ID
                        </p>
                      </div>
                      <div className="nsh-session-access">
                        {bookedSlots && getAccessLink()}
                      </div>
                    </div>
                    <div className='nsh-session-details-middle'>
                      <div className="nsh-toggle-section">
                        <span
                          className='nsh-timing-toggle'
                          onClick={() => {
                            setToggleDesc(false)
                            setTimeout(() => {
                              setToggleTiming(prev => !prev)
                            }, 100);
                          }}
                        >
                          {toggleTiming ?
                            <span>Hide session timings</span>
                            : <span>Show session timings</span>
                          }
                          <motion.img
                            src={downArrow}
                            alt="arrow"
                            width={'15px'}
                            className='nsh-timing-arrow'
                            animate={{ rotate: toggleTiming ? 180 : 0 }}
                            transition={{ duration: 0.3 }}
                          />
                        </span>
                        <span
                          className='nsh-desc-toggle'
                          onClick={() => {
                            setToggleTiming(false)
                            setTimeout(() => {
                              setToggleDesc(prev => !prev)
                            }, 100);
                          }}
                        >
                          {toggleDesc ?
                            <span>Hide session details</span>
                            : <span>Show session details</span>
                          }
                          <motion.img
                            src={downArrow}
                            alt="arrow"
                            width={'15px'}
                            className='nsh-timing-arrow'
                            animate={{ rotate: toggleDesc ? 180 : 0 }}
                            transition={{ duration: 0.3 }}
                          />
                        </span>
                      </div>
                    </div>
                    <motion.div
                      className="nsh-session-details-body w-full"
                      initial={{ height: 0, opacity: 0 }}
                      animate={{
                        height: toggleTiming ? "auto" : 0,
                        opacity: toggleTiming ? 1 : 0,
                      }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{
                        ease: 'easeInOut',
                        duration: 0.3,
                      }}
                    >
                      {bookedSlots.map((slot: any, index: number) => (
                        <div key={index}>
                          <div className="line"></div>
                          <div className="nsh-session-slot">
                            <div className="nsh-session-desc">
                              <div className="batch-details">
                                <p>
                                  <span>Day {index + 1}</span>
                                  &nbsp;&nbsp;
                                  <span>{format(slot.batch_start_time, "EEEE, LLLL dd")}</span>
                                </p>
                                <p>
                                  <span>{format(slot.batch_start_time, "hh:mm a")}</span>
                                  <span> - </span>
                                  <span>{format(slot.batch_end_time, "hh:mm a")}</span>
                                </p>
                              </div>
                            </div>
                            <div className="nsh-session-access">
                              {getSessionStatus(slot.batch_start_time, slot.batch_end_time) === "past" ?
                                <p className="nsh-session-access-past">&#10004; Complete</p>
                                : getSessionStatus(slot.batch_start_time, slot.batch_end_time) === "present" ?
                                  <p className="nsh-session-access-present">Ongoing</p>
                                  : <p className="nsh-session-access-future">Yet to start</p>
                              }
                            </div>
                          </div>
                        </div>
                      ))}
                    </motion.div>
                    <motion.div
                      className="nsh-session-details-body w-full"
                      initial={{ height: 0, opacity: 0 }}
                      animate={{
                        height: toggleDesc ? "auto" : 0,
                        opacity: toggleDesc ? 1 : 0,
                      }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{
                        ease: 'easeInOut',
                        duration: 0.3,
                      }}
                    >
                      <SessionDetails />
                    </motion.div>
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default NFOSessionHistory