import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div>
        <b className="footers">
          Pockvue Solutions Pvt Ltd | An initiative by Streak
        </b>
    </div>
  )
}

export default Footer